
import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import Loading from "./components/Loading";



function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      setTimeout(() => setIsLoading(false), 1200);
    };
  
    if (document.readyState === "complete") {
      handleLoad();
    } else {
      window.addEventListener("load", handleLoad);
    }
  
    return () => window.removeEventListener("load", handleLoad);
  }, []);

  // Hiển thị trang Loading trước, sau đó hiển thị nội dung chính
  return isLoading ? <Loading /> : (
    <div>
      <Header />
      <HeroSection />
    </div>
  );
}

export default App;
