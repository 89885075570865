import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(""); 

  const navLinks = ["About Us", "Docs", "Blogs", "Technology", "Contact"];

  return (
    <div className="fixed w-full z-40 bg-[linear-gradient(111deg,rgba(0,0,0,0.20)_-8.95%,rgba(255,255,255,0.00)_114%)] border-b border-gray-700 flex justify-between items-center py-[14px] px-[4.68vw] lg:px-[15.625vw]">
      {/* Logo */}
      <img src="/images/Logo.png" alt="Logo" className="h-8 w-auto" />

      {/* Navigation */}
      <nav className="hidden md:flex space-x-10 text-white lg:pr-[0.5vw]">
  {navLinks.map((link) => {
    const isActive = activeLink === link;
    return (
      <a
  key={link}
  href={`#${link.replace(/\s+/g, "")}`}
  className={`relative text-base font-medium transition-all duration-300 group 
    ${isActive ? "text-white" : "text-gray-300"}
    hover:text-white
  `}
  onClick={() => setActiveLink(link)}
>
  {link}
  {/* Vùng sáng */}
  <span className="absolute inset-0 scale-75 opacity-0 transition-all duration-300 group-hover:opacity-100 group-hover:blur-[28px] bg-green-500"></span>
  {/* Thanh gạch dưới phát sáng */}
  {isActive && (
    <span className="absolute -bottom-1 left-0 w-full h-0.5 bg-green-500 shadow-[0px_0px_12px_1.5px_#4F0]"></span>
  )}
</a>

    );
  })}
</nav>


      {/* Hamburger Menu */}
      <div className="md:hidden">
        <button onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? <FaTimes size={24} color="white" /> : <FaBars size={24} className="text-white" />}
        </button>
      </div>

      {/* Mobile Menu */}
      {menuOpen && (
        <div
          className="fixed top-0 right-0 w-2/3 h-screen text-white flex flex-col items-end p-6 z-30 md:hidden"
          style={{
            backdropFilter: "blur(100px)",
            backgroundImage: "url('/images/Sidemenu.png')",
          }}
        >
          <button className="mb-4" onClick={() => setMenuOpen(false)}>
            <IoCloseSharp size={24} />
          </button>
          {navLinks.map((link) => (
            <a
              key={link}
              href={`#${link.replace(/\s+/g, "")}`}
              className={`text-lg py-5 transition-all duration-300
                ${activeLink === link ? "text-green-400 font-semibold" : "text-gray-300"} 
                hover:text-green-400`}
              onClick={() => {
                setActiveLink(link);
                setMenuOpen(false);
              }}
            >
              {link}
            </a>
          ))}
        </div>
      )}
    </div>
  );
}
