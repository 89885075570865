import React from "react";
import { ReactComponent as ArrowIcon } from "../assets/arrow.svg";

export default function Button({ text = "CONTACT US" }) {
  return (
    <button
      className="relative inline-flex px-5 py-1.5 justify-center items-center scale-100 xl2:scale-125 xl2:ml-5
                 rounded-[24px_0px] border-2 border-[#00FF80] 
                 bg-[rgba(0,255,128,0.15)] 
                 text-white font-semibold text-lg transition-all duration-300 
                 group overflow-hidden"
    >
      {/* Vùng sáng ban đầu (mờ) */}
      <span
        className="absolute inset-0 scale-100 opacity-50 transition-all duration-500 
                   ease-out group-hover:opacity-100 group-hover:scale-150 
                   bg-[radial-gradient(ellipse,rgba(0,255,128,1)_0%,rgba(0,255,128,0)_70%)]
                   group-hover:bg-[radial-gradient(circle,#00FF80_0%,rgba(0,255,128,0)_70%)]"
      ></span>

      {/* Nội dung Button */}
      <div className="relative z-10 flex items-center justify-center w-full h-full gap-3">
        <span className="text-main text-[15px] font-medium">{text}</span>
        <ArrowIcon className="w-6 h-6 text-main" />
      </div>
    </button>
  );
}
