import React, { useState, useEffect } from "react";

export default function Loading() {
    const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100; // Dừng ở 100%
        }
        return prev + 2; // Tăng dần
      });
    }, 20); // 100ms mỗi lần tăng

    return () => clearInterval(interval);
  }, []);
  
  return (
    <div
      className="relative w-full h-screen bg-black overflow-hidden bg-cover bg-center flex items-center justify-center"
      style={{ backgroundImage: "url('/images/bg.png')" }} // Thêm background image
    >
      {/* Vùng sáng hình oval */}
      <div
        className="absolute pointer-events-none"
        style={{
          width: "1461px",
          height: "600px", // Chiều cao nhỏ hơn để tạo hình oval
          borderRadius: "1461px / 600px", // Bo tròn theo tỷ lệ ellipse
          opacity: "0.8",
          background:
            "radial-gradient(44.81% 44.81% at 50% 50%, rgba(141, 184, 2, 0.40) 0%, rgba(0, 50, 3, 0.40) 100%)",
          mixBlendMode: "screen",
          filter: "blur(100px)",
        }}
      ></div>

      {/* Thanh Loading */}
      <div className="relative w-[60%] md:w-[25vw] h-4 bg-transparent border border-green-400 rounded-md p-[2px] flex gap-[1px] md:gap-[2px]">
        {Array.from({ length: 50 }).map((_, index) => (
          <div
            key={index}
            className="h-full w-[8px] bg-loading transition-all duration-300"
            style={{
              opacity: index < (progress / 2) ? 1 : 0.2, // Hiệu ứng sáng dần
            }}
          ></div>
        ))}
      </div>
    </div>
  );
}
