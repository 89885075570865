import React, { useState, useEffect } from "react";
import Button from "./Button";

export default function HeroSection() {
  const [mousePosition, setMousePosition] = useState({ x: "50%", y: "50%" });
  const [videoSrc, setVideoSrc] = useState(
    window.innerWidth < 768 ? "/videos/Mobile.mp4" : "/videos/Desktop.mp4"
  );

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    setMousePosition({ x: `${clientX}px`, y: `${clientY}px` });
  };

  useEffect(() => {
    const updateVideoSource = () => {
      setVideoSrc(window.innerWidth < 768 ? "/videos/Mobile.mp4" : "/videos/Desktop.mp4");
    };

    window.addEventListener("resize", updateVideoSource);
    return () => window.removeEventListener("resize", updateVideoSource);
  }, []);

  

  return (
    <div
      className="relative w-full h-screen bg-black overflow-hidden bg-cover bg-center"  
      onMouseMove={handleMouseMove}
    >
      {/* Video Background */}
      <video
        key={videoSrc}
        className={`absolute top-0 left-0 z-0 w-full h-full object-cover transition-opacity duration-500`}
        autoPlay
        loop
        muted
        poster='/images/bg.png'
        playsInline
      >
        <source src={videoSrc} type="video/mp4" />
      </video>

      {/* Hiệu ứng phát sáng theo chuột */}
      <div
        className="absolute pointer-events-none"
        style={{
          top: `calc(${mousePosition.y} - 293px )`, // Center the effect
          left: `calc(${mousePosition.x} - 211px )`, // Center the effect
          width: "423px",
          height: "586px",
          borderRadius: "986px",
          opacity: "0.8",
          background:
            "radial-gradient(44.81% 44.81% at 50% 50%, rgba(141, 184, 2, 0.80) 0%, rgba(0, 50, 3, 0.80) 100%)",
          mixBlendMode: "screen",
          filter: "blur(250px)",
          transition: "transform 0.1s ease-out",
        }}
      ></div>

      {/* Nội dung */}
      <div className="absolute left-[50vw] md:left-[15.625vw] top-[33%] md:top-[50%] transform -translate-x-1/2 md:translate-x-0 -translate-y-1/2 z-10 items-center text-center md:text-left flex flex-col md:items-start transition-opacity duration-500">
        <p className="normal-text font-nasal text-white z-10">Future</p>
        <p className="neon-text">
          security <br /> technology
        </p>
        <p className="font-nasal text-main font-normal text-[36px] md:text-5xl xl2:text-[56px] leading-normal pb-4">
          Palm by Palm
        </p>
        <Button text="CONTACT US" />
      </div>

      
    </div>
  );
}
